/* src/styles/HomePage.css */

/* Styling for the top image container */
.top-image-container {
    width: 100%;
    overflow: hidden;
    margin-bottom: 20px;
  }
  
  /* Styling for the top image to make it responsive */
  .top-image {
    width: 100%;
    height: auto;
    max-height: 400px; /* Optional: Adjust this value as needed to control the height */
    object-fit: cover; /* Ensures the image covers the container area without losing its aspect ratio */
    display: block;
    margin: 0 auto;
  }

  .imgMUSC {
    height: 50%;
    width: 50%;
  }
  