/* Footer Container */
.footer-container {
    width: 100%;
  }
  
  /* Row Styling to Remove Gaps */
  .footer-row {
    margin: 0;
  }
  
  /* Footer Sections */
  .footer-section {
    padding: 20px;
    border: none; /* Remove any unwanted borders */
    margin: 0; /* Remove default margins */
  }
  
  /* Specific Colors for Each Section */
  .footer-red {
    background-color: #DA291C; /* Red section */
    color: white;
  }
  
  .footer-white {
    background-color: #FFFFFF; /* White section */
    color: #000000;
  }
  
  .footer-black {
    background-color: #000000; /* Black section */
    color: white;
  }
  
  /* Links Styling for Better Visibility */
  .footer-link {
    color: inherit;
    text-decoration: none;
  }
  
  .footer-link:hover {
    text-decoration: underline;
  }
  
  /* Ensure Columns Fit Perfectly */
  .no-gutters {
    padding: 0;
    border-spacing: 0;
  }
  
  .border {
    width: 10px;
  }