/* MatchPage.css */

/* Custom Tabs */
.custom-tabs .nav-link {
    color: #DA291C !important; /* Use primary color for tab text */
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 5px;
  }
  
  .custom-tabs .nav-link.active {
    background-color: #DA291C !important; /* Highlight active tab with primary color */
    color: #fff !important;
  }
  
  .tab-title {
    font-size: 1.1rem; /* Increase the size of the tab title for better visibility */
  }
  
  /* Custom Card Styling */
  .custom-card {
    border: 1px solid #e0e0e0; /* Light border for a clean look */
    transition: transform 0.3s, box-shadow 0.3s; /* Smooth transitions for hover effect */
    background-color: #f9f9f9; /* Soft background color */
  }
  
  .custom-card:hover {
    transform: translateY(-10px); /* Lift card on hover for interactivity */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); /* Add a shadow to give depth */
  }
  
  .custom-card-header {
    background-color: #FBE122; /* Use secondary color for header */
    color: #000;
    font-weight: bold;
    padding: 15px;
    font-size: 1.25rem;
  }
  
  .custom-card-body {
    padding: 20px;
  }
  
  /* Badge Styling */
  .status-badge {
    font-size: 0.9rem;
    font-weight: 600;
  }
  
  .badge-success {
    background-color: #28a745 !important; /* Green for open */
  }
  
  .badge-info {
    background-color: #17a2b8 !important; /* Blue for upcoming */
  }
  
  .badge-danger {
    background-color: #dc3545 !important; /* Red for closed */
  }
  
  .competition-badge {
    background-color: #007bff !important; /* Blue for competition */
    font-size: 0.85rem;
  }
  
  /* Crest Image Styling */
  .custom-crest-img {
    max-width: 100%;
    max-height: 80px;
    border-radius: 8px; /* Rounded edges for a softer look */
  }

  .bg-purple {
    background-color: #6f42c1 !important; /* Bootstrap purple */
    color: white !important;
  }
  
  .bg-orange {
    background-color: #fd7e14 !important; /* Bootstrap orange */
    color: white !important;
  }
  
.tabs-container {
  overflow-x: auto;
  white-space: nowrap;
}

.custom-tabs {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
}

.custom-tabs .nav-link {
  flex: 1;
  text-align: center;
  white-space: nowrap;
  min-width: 80px; /* Minimum width for each tab */
  padding: 0.5rem; /* Reduced padding for compact view */
  font-size: 0.9rem; /* Reduced font size to fit better */
}