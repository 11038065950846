/* src/index.css */

/* Define new primary and secondary color overrides */
:root {
  --bs-primary: #DA291C;   /* Primary color */
  --bs-secondary: #FBE122; /* Secondary color */
}

.custom-primary-button {
  background-color: #DA291C !important;
  border-color: #DA291C !important;
  color: #ffffff !important; /* Ensure text color contrast is maintained */
}

/* Override Bootstrap primary button */
.btn-primary {
  background-color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
}

/* Override Bootstrap secondary button */
.btn-secondary {
  background-color: var(--bs-secondary) !important;
  border-color: var(--bs-secondary) !important;
}

/* Override the text primary and secondary classes */
.text-primary {
  color: var(--bs-primary) !important;
}

.text-secondary {
  color: var(--bs-secondary) !important;
}

/* Override backgrounds */
.bg-primary {
  background-color: var(--bs-primary) !important;
}

.bg-secondary {
  background-color: var(--bs-secondary) !important;
}

/* Body styling */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f7; /* Light background color for the entire page */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
